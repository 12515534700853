.l-footer{
  background-color: $footer-bg;
  margin-top: $footer-margin-top;
  padding-top: $footer-padding-top;
  a{
    color:$body-color;
  }
}
.copyright{
			padding-top:0rem;
      font-size: $small-font-size;
      font-weight: $btn-font-weight;
			span{

				&:before{
					content: url(/img/icon_coeur.svg);
    			padding-left: .4rem;
    			padding-right: .4rem;
				}
				&:after{
					content: url(/img/icon_123.svg);
          padding-left: .4rem;
    			padding-right: 0.4rem;
				}
			}
      ._blank{
        text-decoration: underline;
        text-underline-offset: 3px;
        color:$body-color;
      }
}

/*back to top*/
#link-main-container {
    transition: opacity .5s, visibility .5s;
    background: $primary;
    width:55px;
    height:55px;
    border-radius: 50%;
  	visibility: hidden;
  	color:$white;
  	position: fixed;
  	z-index:1;
  	bottom: 2rem;
    right: 2rem;
    svg{
      width: 20px;
      height:32px;
      margin-top:4px;
      margin-right:2px;
    }
  	&.show {
  		opacity: 1;
  		visibility: visible;
		}
		span{
			display:block;
			line-height:1;
			max-width:60px;
		}

  @include media-breakpoint-mobile {
    &:hover{color:$primary;}
  }
  @include media-breakpoint-up(lg) {
	  #product &{
			bottom: 2rem;
		}
	}
}
