.social-sharing{
  @extend .d-flex;
  @extend .flex-wrap;

  .displayCustomBanners1{
    @include media-breakpoint-mobile() {
      width:100%;
      .skeepers-widget-wrapper-dd2575eb-fd08-4a2e-b155-68da96c47372{
        display: inline-block !important;
      }
    }

  }
}
.social-sharing--product{
  margin: $spacer 0;
  @extend .align-items-center;
}
.modal .social-sharing--product{
  margin: 0;
}

.social-share-btn{
  display: block;
  margin: 0 $spacer/2;
  &:not(use) >svg{
  	 width: $social-share-size;
  	 height: $social-share-size;
  	 fill:$body-color;
  }
  &:hover{
  	svg{fill:$primary;}
  }
  &.facebook:hover svg{fill:#1877F2;}
  &.twitter:hover svg{fill:#1DA1F2;}
  &.instagram:hover svg{fill:#C42D91;}
  &.youtube:hover svg{fill:#f00;}
  &.pinterest:hover svg{fill:#E60023;}
  &.linkedin:hover svg{fill:#0274b3;}
}

.social-share-btn--product{
  background-size:80%;
  background-color: #fff;
  @extend .shadow-sm;
  border-radius: 50%;
  transition: box-shadow 0.3s ease-in-out;
  &:hover{
    box-shadow:$card-box-shadow;;

  }

  &.facebook {
    background-image: url(../img/facebook-gray.svg);
    &:hover{
    background-image: url(../img/facebook-blue.svg);
    }
  }
  &.twitter {
    background-image: url(../img/twitter-gray.svg);
    &:hover{
      background-image: url(../img/twitter-blue.svg);
    }
  }
  &.googleplus {
    background-image: url(../img/gplus-gray.svg);
    &:hover{
      background-image: url(../img/gplus-blue.svg);
    }
  }
  &.pinterest {
    background-image: url(../img/pinterest-gray.svg);
    &:hover{
      background-image: url(../img/pinterest-blue.svg);
    }
  }

}
.social-share-btn--footer {
  transition: background-color 0.3s ease-in-out;
}

.facebook{
    margin-left: -14px;
}
