.modal.fade .modal-dialog__offcanvas
{
  width: 375px;
  height: 100%;
  margin: 0;
  max-width: 90%;
  position: absolute;
  top: 0;
  //left:0;
  transform: translateX(-100%);
  transition: transform .3s ease-out;
}
.modal.show .modal-dialog__offcanvas
{
  transform: translateX(0);
}
.modal.fade .modal-dialog__offcanvas.modal-dialog__offcanvas--right
{
  right: 0;
  transform: translateX(100%);
}
.modal.show .modal-dialog__offcanvas.modal-dialog__offcanvas--right
{
  transform: translateX(0);
}

.modal-dialog__offcanvas .modal-content
{
  height: inherit;
	box-shadow: none;
  border-width: 0;
  border-radius: 0;
}
.modal-dialog__offcanvas .modal-header
{
  border-radius: inherit;
}
.modal-dialog__offcanvas .modal-body
{
  overflow-y: auto;

  height: inherit;
}


#mobile_top_menu_wrapper{
  	&.fade{
			.modal-dialog__offcanvas{
				width:100%;
				max-width:100%;
				.modal-content{
						border:0;
						box-shadow: none;
						.modal-header{
							 border-bottom:3px solid $primary;
						}
						.modal-body{
							padding:0px $grid-gutter-width / 2 $grid-gutter-width / 2;
						}
						.language-selector-wrapper{
							display:flex;
							align-items:center;
						}
				}
			}
		}
}

#offcanvas_search_filter.modal.fade{
/* 	z-index:1000000000; */
 	.modal-dialog__offcanvas{
  	width:100%;
		max-width:100%;
		.modal-content{
			/*background-color:$white;	*/
		}
		.modal-header{
    		@extend .h4;
				padding: 0.5rem 1rem 0.4rem;
				border-color:$body-color;
				margin-bottom:0;
				.PM_ASearchTitle{
						padding-top: 0.2rem;
				}
				/*.close {
					padding:0;
					margin:0;
					opacity: 1;
					color:$body-color;
					span{
						font-size: 2.3125rem;
						vertical-align:sub;
					}
				}*/
			}
		.modal-body {
    	padding: 0rem;
    	.card{
    		border-width:0;
    	}
    	.card-block{
    		/*padding: 0rem;	*/
    	}

			.PM_ASBlockOutputVertical .PM_ASCriterionsGroup {
    		/*margin-bottom:0rem;*/
    		/*margin-left:0rem;*/
			}


		}
	}
}

#offcanvas-cms {
	 z-index: 9999;
	.modal-dialog__offcanvas .modal-content{
	  border-left:4px solid $primary;
	}
	.modal-header{
		border-bottom:0;
		flex-direction:column;
		img{
			width:33%;
		}
		.close{opacity:1;}
	}
}
.btn-block{
  background: $primary;
  color:$white;
}
.btn-primary:not(:disabled):not(.disabled):active{
  color:$white;
}
