#product{
  .h1{
    @include font-size(1.875rem);
    font-family: $font-bold;
     @include media-breakpoint-mobile() {
       font-size: 1.25rem;
       margin-top:1.25rem;
     }
  }
  header {
    border-bottom:1px solid $border-color-2;
    margin-bottom:15px;
  }
  .product_images_max{
    /*max-width: 930px;*/
    @include media-breakpoint-mobile() {
      max-width: inherit;
    }
  }
}
.products{
  @extend .row;
  align-items:stretch;
  @include media-breakpoint-mobile() {
    padding-left: 5px;
    padding-right: 5px;
    .product-miniature {
      padding-bottom: .5rem;
      padding-right: 10px;
      padding-left: 10px;
    }
  }
  .card-product{
    border: none;
    background: $gray-200;
  }
}
.product-miniature{
  @extend .col-6;
  padding-bottom:1rem;
}
@include media-breakpoint-desktop {
  .product-miniature{
    @include make-col(4);
  }

  .layout-full-width .product-miniature{
    @include make-col(3);
  }
}

@media (max-width: 320px) {
  .product-miniature{
    @include make-col(12);
  }
}

.slick-slide .product-miniature{
  max-width: 100%;
}
/* Product miniature*/
.card-product.card{
  height:100%;
  position: relative;
  background-color: $pm-bg-color;
  border-width: $pm-border-width;
  border-color: $pm-border-color;
  &:hover
  {
    .highlighted-informations{
      transform:translateY(0);
    }

  }

  .discount{
    display:none;
  }
}

.card-img-top {
  overflow: hidden;
  .product-thumbnail img{
    width: 100%;
  }

}

.highlighted-informations{
  position: absolute;
  bottom: 0;
  width:100%;
  background-color: $pm-highlight-bg-color;
  transform:translateY(100%);
  transition:transform .3s;
  .card-product:hover & {
    transform:translateY($pm-highlight-translateY);
  }
}
.quick-view{
  cursor:pointer;
}

.product-flags{
  position:absolute;
  top:0;
  margin-bottom: 0;
  width: 100%;
  z-index: 1;
  left:0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: $font-bold;
}
.page-content--product .product-flags{
  left:0;
}
.product-flag{
  top:0;
  margin-bottom: 12px;
  padding: .25rem .625rem;
  color: #fff;
  background: theme-color('primary');
  text-transform: uppercase;
  font-size: .75rem;

  &.discount-product{
    color: #353535;
    font-size: 1rem;
    background: transparent;
  }
  &.new{
    background: none;
    color: #353535;
    letter-spacing: 1.16px;
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid #626262;
  }
}
#miniaddtocart{
    @include media-breakpoint-mobile() {
      font-family: $font-black;
      letter-spacing: inherit;
      font-size: 11px;
    }
}
.color{
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  box-shadow: 2px 2px 4px 0 rgba(0,0,0,.2);
  border: 1px solid rgba(0,0,0,.05);
  cursor: pointer;
  background-size: contain;
}

.product-title{
  font-size: 0.875rem;
  letter-spacing: 1.75px;
  font-family: $font-bold;
  line-height: 1.6;
  min-height: 50px;
  @include media-breakpoint-mobile() {
    letter-spacing: inherit;
    line-height: normal;
    a{
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }


  }
  & a {
    color: $body-color;
    text-decoration: none;
  }
  @include media-breakpoint-mobile() {
    text-transform: inherit;
  }
  .card-product:hover & {
    color: $body-color;
    & a {
      color: $body-color;
    }
  }
}

/* PRODUCT PAGE */

.products-imagescover{
  .slick-dots{
    display: flex;
    justify-content: center;
    /*margin-top:-3rem;*/
  }
  .slick-dots li button{
    align-items: center;
    width: 12px;
    height: 12px;
    border: none;
    display: block;
    margin-right: 0.5rem;
    background: #353535;
    font-size: 0;
    border-radius: 50%;
    opacity: 0.3;
  }
  .slick-active{
    z-index:995;
  }


}
.product-img:not(:first-child) .rc {
  display: none;
}
.slick-initialized .product-img .rc{
  display: block;
  background:$gray-200;
}

.product-thumbs{
  width:$product-thumb-wrap-width;
  height: $product-thumb-wrap-height;
  margin:0 auto;
  &[data-count="2"]{
    width:$product-thumb-wrap-width-2;
  }
  @include media-breakpoint-up(xxl) {
  	width:$product-thumb-wrap-width-xxl;
  	height:$product-thumb-wrap-height-xxl;
  	&[data-count="2"]{
    	width:$product-thumb-wrap-width-2-xxl;
  	}
  }
  @include media-breakpoint-mobile-portrait {
  		width:$product-thumb-wrap-width-mobile;
  		height:$product-thumb-wrap-height-mobile;
  }
  
  .slick-slide > div{
    cursor: pointer;

  }
  &:not(.slick-initialized) > div:not(:first-child){
    display: none;
  }

}
.product-thumb{
	position:relative;
  margin: $product-thumb-margin;
  width:$product-thumb-width;
  height:$product-thumb-height;
  @include media-breakpoint-up(xxl) {
  	width:$product-thumb-width-xxl;
 		height:$product-thumb-height-xxl;
  }
  @include media-breakpoint-mobile-portrait {
  		margin: $product-thumb-margin-mobile;
  		width:$product-thumb-width-mobile;
  		height:$product-thumb-height-mobile;
  }
  border:1px solid $white;
  &.slick-current {
    border: 1px solid theme-color('primary');
  }
  &.video-thumb{
  	div.thumb{
  			height:$product-thumb-height-2;
  			max-width: 100%;
		    background-size: cover;
		    background-position: center;
		    display: flex;
		    justify-content: center;
		    align-items: center;	
		    @include media-breakpoint-up(xxl) {
			  	height:$product-thumb-height-xxl-2;	
  			}
		    @include media-breakpoint-mobile-portrait {
	    		height:$product-thumb-height-mobile-2;	
	    	}
  	}
  }
}

/*.product-thumbs{
  width:100%;
  margin:0 auto;
  &[data-count="2"]{
    width:100%;
  }
  .slick-slide > div{
    cursor: pointer;

  }
  &:not(.slick-initialized) > div:not(:first-child){
    display: none;
  }

}*/

.images-container:hover .btn-zoom{
  display: block;
}
.label-color{
  line-height: 1;
  margin-right: $spacer/2;
}
.product-variants-item .color{
  width:2rem;
  height:2rem;
  border: 2px solid transparent;
}

.input-color:checked+span, .input-color:hover+span, .input-radio:checked+span, .input-radio:hover+span {
  border-color: $colorbox-border-color;
}

//product prices
.product__product-price.has-discount{
  .current-price{
    display: inline-block;
  }
}
.price{
  color:$body-color;
  font-family: $font-bold;
  &.current-price-discount{
    color:$primary;
    font-family: $product-price-discount-font-family;
  }
}
.regular-price{
  color:$body-color;
  text-decoration: line-through;
  font-family: $font-bold;
}
.discount{
  text-transform: uppercase;
  color: #FFF;
  background-color: $product-price-discount-color;
  font-weight: 600;
  display: inline-block;
  padding: .25*$spacer;
}

//product add to cart
.btn-add-to-cart{
  margin-top:1.188rem;
  text-transform: uppercase;
  font-family: $font-bold;
  color:$body-color;
  white-space: nowrap;
  background: rgba($primary, 0.4);
  border:none;
  padding: 1.125rem 1.125rem;
  &:hover, &:focus, &:active{
    background: $primary;
    color:$body-color;
  }
}

//product tabs
.product-tabs{
  .nav-tabs{
    font-size: $font-size-base * .9;
  }
  //@extend .card;
  //@extend .card-block
  //box-shadow:$card-box-shadow;;
}
.card--reassurance{
  box-shadow:$card-box-shadow;;
}

// modal add to cart
.cart-content{
  padding: $spacer;
  background: theme-color('light');
}

//product pack
.pack-product-container{
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  padding: 0 $spacer;
  font-size: $font-size-sm;
}
.mask{
  width: 55px;
}
.thumb-mask{
  padding: $spacer 0;
}
.pack-product__img{
  border: 1px solid $border-color;
}
.pack-product-name{
  @extend .flex-grow-1;
  @extend .mx-4;
}
.pack-product-quantity{
  border-left:1px solid $border-color;
}
.pack-product-price{
  padding-right: $spacer/2;

}
.pack-product-quantity{
  @extend .d-flex;
  @extend .align-items-center;
  @extend .align-self-stretch;
  padding-left: $spacer;
}

//modal cart
.modal-cart__image{
  margin-right: $spacer;
  max-width: 200px;

  height: auto;
}
.modal-cart__name{
  color: $primary;
  font-weight: $font-weight-bold;
}
.product-total{
  font-weight: $font-weight-bold;
}
.cart-content{
  > p:not(:last-child){
    margin-bottom: $spacer/2;
  }
}
@include media-breakpoint-mobile {
  .modal-cart__image{
    max-width: 120px;
  }
  .blockcart-modal {
    .divide-right{
      display: flex;
      justify-content: center;
    }
    .modal-footer {
      position: fixed;
      bottom: 0;
      background: #fff;
      justify-content: center;
      left: 0;
      width: 100%;
      box-shadow: 0px -0.25rem 0.5rem rgba($black, .15);
    }
    .modal-body{
      margin-bottom: ($btn-padding-y * 2) + ($btn-font-size*$btn-line-height) + $modal-inner-padding * 2;
    }
  }
}
@include media-breakpoint-down(xs) {
  .modal-cart__image{
    max-width: 70px;
  }
}
//spinner add to cart
.btn-add-to-cart {
  .btn-add-to-cart__spinner {
    @extend .spinner-border;
    width: $spinner-width-addtocart;
    height: $spinner-height-addtocart;
    border-width: $spinner-borderwidth-addtocart;
    display: none;
  }
  &.is--loading{
    .btn-add-to-cart__spinner {
      display: inline-block;
    }
    .btn-add-to-cart__icon {
      display: none;
    }
  }
}

.products-selection{
  justify-content: space-between;
  align-items: center;
}
.pack-miniature-item{
  margin-bottom: $spacer / 2;
}
.ratio1_1{
  display: block;
}
.pack-product-name{
  margin: 0 $spacer * 1.5;
  flex-grow: 1;
}
.product-miniature{
  margin-bottom: $spacer ;
  .card-body{
    padding:1rem;
  }
}
.product__card-img{
  position: relative;
}
.product__card-desc{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.highlighted-informations{
  text-align: center;
  padding: $spacer / 2;
}
.quantity__label{
  margin-bottom: 0;
  margin-top: $spacer;

}
.product-add-to-cart{
  .product-quantity{
  margin-top: -($spacer);
  }

.add{
  margin-top: $spacer;

}
}
.product-customization{
  margin-bottom: $spacer * 1.5;
}
.product-discounts{
  margin-bottom: $spacer * 1.5;
}
.product__quantity{
  margin-bottom: 0;
}
.sort-by-row{
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.total__item-show{
  text-align: center;
  font-size: $font-size-sm;
  margin-top: $spacer;
}
.variant-links{
  display: flex;
  justify-content: center;
}
.product__download{
  text-transform: uppercase;
}
.card--address{
  height: 100%;
  text-align: center;
  justify-content: center;
}
.invoice__address{
  margin-top: $spacer;
}
.invoice-address{
  height: 100%;
  text-align: center;
  justify-content: center;
}
.nav-tabs{
  justify-content: center;
}

.product-description{
  .add-to-cart{
    background: rgba($primary, 0.4);
    color:$body-color;
    padding: 12px 0 12px 0;
    width:100%;
    border:none;
    transition: 0.5s;
    font-size: 0.75rem;
    font-family: $font-black;
    &:hover{
      background: $primary;
    }
  }
}
.ebcategoryimage{
  padding-bottom:2rem;
@include media-breakpoint-mobile {
  .bloc-image{
    background-position: inherit !important;
  }
}
}

.product-information{
  margin-top:1.56rem;
  .label{
    font-family: $font-bold;
  }
  .price, .regular-price{
    font-size: 1.25rem;
  }
}
.tab-content{
  .nav-link{
    text-transform: uppercase;
    font-family: $font-bold;
    color:$primary;
  }
  section{
    border-bottom:1px solid $border-color-2;
    .collapse {

    }
  }
  .nav-link{
    position: relative;
    padding-left: 0;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    &:after{
      content: "\e145";
      font-family: Material Icons;
      font-feature-settings: "liga" 1;
      position: absolute;
      top: 18px;
      right: 0;
      font-size: 1.125rem;
      color:$body-color;
    }
  }
  #extra-3{
    li{
      margin-bottom:1rem;
    }
  }
}


.select_fragrance{
    border-color:$skin;
    margin-bottom:1.652rem;
    text-transform: inherit;
    font-size:1rem;
    padding: 8px;
    text-align: left;
    font-family: inherit;
    @include media-breakpoint-mobile() {
      width: 100%;
    }
}
.bestkit_pfeatures_wrapper{
  .dropdown-toggle:after{
    margin-left: 2.5rem;
    margin-right:11px;
    padding: 3.5px;
    border: 2px solid;
    border-top: 0;
    border-left: 0;
    @include media-breakpoint-mobile() {
      position: absolute;
      right: 1rem;
      top: 14px;
      margin-left: inherit;
      margin-right:inherit;
    }
  }
}
.dropdown-fragrance{
  img{
    width:30px;
    margin-right:11px;
  }
  .dropdown-item{
    padding: 10px 15px 7px 16px;
  }
  a{
    color:$body-color;
  }
}
.ico-product{
  margin-top:2.5rem;
  img{
    max-width: 40px;
    margin-bottom:10px;
    @include media-breakpoint-mobile {
      max-width: 30px !important;
    }
  }
  .cb-item{
    margin-right:3.12rem;
      @include media-breakpoint-mobile {
        margin-right:1rem;
      }
    &:last-child{
      margin-right:inherit;
    }
  }
  .custom-html{
    font-family: $font-medium;
      @include media-breakpoint-mobile {
        font-size: 0.75rem;
      }
  }
}
.product-information{
   a.ancre{
    color:$body-color;
    font-family: $font-bold;
    padding-bottom:1rem;
    display: block;
    cursor:pointer;

      &:hover{
        text-decoration: underline;
        text-underline-offset: 3px;
      }

    @include media-breakpoint-mobile() {
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  }
}
.videoProductMobile {
  width: 100%;
  object-fit: cover;
  min-height: 358px;
}
