body{

}

p a{
  text-decoration: underline;
}
p{
  margin-bottom:0;
}

sup{
  top:-0.3em;
}

.l-wrapper{
  @if $enable-fluid-layout {
  	 @extend .container-fluid;
    &:not(.l-wrapper--boxed){
  		max-width: 1920px; // blocage de la taille pour les 2560px
  		#index &,#category &,#module-ambjolisearch-jolisearch &,#module-pm_advancedsearch4-searchresults &,  #cms.elementor-page & {max-width:inherit; padding: 0;}
  	}
  	.full-page &:not(.l-wrapper--boxed){
  		max-width: 1810px; // blocage de la taille pour les full pages de listing produit
  	}
  } @else {
    @extend .container;
  }
  &--boxed{
    @extend .container;

  }
  @include media-breakpoint-mobile() {
    padding: 0!important;
  }

}
@include media-breakpoint-mobile() {
#checkout{
  .l-wrapper{
    padding: 0 15px 0 15px !important;
  }
}
}
.page-wrapper,.card-block{
  @extend .card;

}
.page-wrapper{
  border:none;
}
.custom-file-label:after{
  height:48px;
  padding-top: 12px;
}
.page-header{
  h1{
    margin-bottom: 0;
    text-align: center;
    @include font-size(1.875rem);
    font-family: $font-bold;
    margin-top: 2.625rem;
  }
}
.page-content,.card-block{
  @extend .card-body;
}
.card-block{
  box-shadow:$card-box-shadow;;
}
.page-footer{
  @extend .card-footer;
  &:empty{
    display: none;
  }

}

.left-column > div,
.right-column > div{
  margin-bottom: $spacer;
}
/* HEADER */

/* FOOTER */
.l-footer{
  background-color: $footer-bg;
  margin-top: $footer-margin-top;
  padding-top: $footer-padding-top;
  ul{
      @include media-breakpoint-mobile() {
        line-height: 1.8;
        margin-top:1rem;
      }
  }
}

/* MISC */
ul {
  list-style: none;
  padding-left: 0;
}
h1,.h1{
  @include h1;
}
h2,.h2{
  @include h2;
}
h3,.h3{
  @include h3;
}
.quick-view{
  display: inline-block;
  @extend .small;
}
.form-group.form-group_forgot-password{
  margin-top: -($form-group-margin-bottom/2);
}

//touch spin

.bootstrap-touchspin
{
  .input-group-btn-vertical{
  position: relative;
  white-space: nowrap;
  width: 1%;
  vertical-align: middle;
  display: table-cell;
  >.btn {
    display: block;
    float: none;
    width: 100%;
    max-width: 100%;
    padding: 6px 10px 0;
    line-height:1.0;
    margin-left: -9px;
    position: relative;
    &:before{
      position: absolute;
      background: #fff;
      font-family:"Material Icons";
      font-weight: 700;
    }
    &.bootstrap-touchspin-up:before{
      content: "\e5ce";
    }
    &.bootstrap-touchspin-down:before{
      content: "\e313";
    }
  }
}
  border:$input-border-width solid $skin;
  > input {
    max-width: 60px;
    border:none;
    box-shadow: none;
    text-align: center;
    font-weight: 700;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  .btn {
    position: relative;
    z-index: 2;
    color:$input-color;
    background-color: $white;

  }
  .input-group-btn{
    display: flex;
    &:first-child{
      margin-right:-$input-border-width;
    }
    &:last-child{
      margin-left:-$input-border-width;
    }
  }
}

.btn {
  position: relative;
  z-index: 2;
  color:$body-color;
  background-color: $white;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 0.875rem;
  font-family: 'lcdp_brandonbold', $headings-font-family;
  text-decoration: none;
  &:focus, &:active {
    box-shadow: inherit !important;
  }
}

.product-add-to-cart{
  .bootstrap-touchspin{
    margin-right:1.375rem;
    max-width: 75px;
    > input {
      max-width: 45px;
      text-align: left;
      -moz-appearance:textfield;
  }
  }

}

#menu-icon{
  .material-icons{
    font-size: 1.875rem;
    color:$body-color;
  }
}

#adtm_menu .li-niveau1 a.a-niveau1 .advtm_menu_span{
    font-family: $font-bold;
}

.btn-link{
  padding: inherit;
}

.btn-default{
  background-color: $secondary;
  &:hover{
    background-color: $primary;
    transition: 0.8s;
  }
}
.btn-primary{
  border:none;
  background: rgba($primary, 0.4);
  &:hover{
    background: $primary;
    color:$body-color;
  }
}
.btn-secondary{
  background-color: $white;
  border-color:$primary;
  &:focus, &:active {
    color: inherit;
    border-color: inherit;
    border:inherit;
  }
  &:hover{
    background-color: $primary;
    transition: 0.8s;
    color:$body-color;
    border-color:$primary;
  }
}
@keyframes underlined {
    from {
        transform: scaleX(1);
    }
    50% {
        transform: scaleX(0);
    }

    100% {
        transform: scaleX(1)
    }
}
.btn-inline{
  background: none;
  padding: inherit;
  font-size: 1rem;
  &:after{
    content: '';
    transform-origin: 0 0;
    transform: scaleX(1);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.6em;
    background: $secondary;
    z-index: -1;
    opacity: 0.8;
    transition: transform .3s;
  }
  .variant{
    &:after{
      background: black;
    }
  }
}

.btn-inline {
  &:hover{
    &::after{
      animation: underlined 1s both;
    }
  }
}

.btn-inline-variant{
  background: none;
  padding: inherit;
  font-size: 1rem;
  &:after{
    content: '';
    transform-origin: 0 0;
    transform: scaleX(1);
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0.6em;
    background: $skin;
    z-index: -1;
    opacity: 0.4;
    transition: transform .3s;
  }
}


.btn-inline-variant {
  &:hover{
    &::after{
      animation: underlined 1s both;
    }
  }
}
.btn-inline-secondary{
  @extend .btn-inline;
  &:after{
    content:inherit;
  }
  text-transform: inherit;
  &:hover{
    text-decoration: underline;
  }
}

/* minimizing reflows and avoiding page jumps */

.rc {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  transition: background 150ms;
  &:after{
    //@extend .spinner-border;
    //content: "";
    border-color: $gray-300;
    position: absolute;
    top: calc(50% - #{($spinner-height/2)});
    left: calc(50% - #{($spinner-width/2)});
    z-index: 2;
    border-right-color: transparent;
  }
  &--lazyload:after{
    display: none;
  }
}

.rc > * {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.rc > a {
  display: block;
}
.ratio2_1{
  padding-top: 50%;
}
.ratio1_2{
  padding-top: 200%;
}
.ratio4_3{
  padding-top: 75%;
}
.ratio16_9{
  padding-top: percentage(9/16);
}
.ratio1_1{
  padding-top: 100%;
}
.ratio3_2{
  padding-top: percentage(1-2/3);
}

/* collapse icons */
.icon-collapse{
  transition: transform 0.4s ease;


}
.icon-collapse[aria-expanded=true]{
  transform: rotateZ(180deg);
}

/* CHECKOUT STEP */
.order-checkout .card-body{
  padding-bottom: 0;

}
.step-edit[aria-expanded="true"]{
  display: none;
}
.step-title
{
  margin-bottom: 0;

}
.-complete.-reachable .step-title .done {
display: inline-block;
}
.-current .step-title .done {
  visibility: hidden;
}
.-current .card-header{
  background-color: $white;
}


.accordion {
  .card-body .card {
    border: 1px solid $card-border-color;
    transition: border 0.1s linear;

  }
  .card-body .card-header{
    text-align: center;
  }
  .selected .card{
    border-color: $success;
    border-width: 2px;
  }
}
  //Personnal info
  .nav-tabs-info{
    font-size: $font-size-base*1.1;
    .active{
      font-family: $font-bold;
    }
  }

  //Addresses

  .address-selector
  {
    .card{
      transition: border 0.1s linear;

    }
    .card-header,
    .card-body{
      cursor: pointer;

    }
  }
  .card-body_add-address{
    padding: $card-spacer-y $card-spacer-x;
    .material-icons{
      opacity: .8;
    }
  }
  // SHipping
.delivery-option{
  padding: $spacer;
  margin-bottom: $spacer;
  border:1px solid $gray-200;
}
.delivery-option-checked{
  border-color: $skin;
}

#checkout{
  .user-info, .blockcart{
    display: none;
  }
  .bg-reass{
    margin-bottom:0;
  }
  @include media-breakpoint-mobile() {
    .step-title{
      font-size:1.25rem !important;
    }
.logo{
  margin-top:1rem;
}
  }
}

#content-checkout-delivery-step{
  .card-body{
    padding-right:0;
    padding-left:0;
  }
}
#delivery{
  label{
    font-family: $font-family-sans-serif ;
  }
}
.carrier-delay{
  font-family: $font-family-sans-serif ;
}
.carrier-extra-content:not(:empty){
  margin-bottom: $spacer*2;
  &:empty{
    display: none;
  }
}
.carrier-name{
  font-size:0.875rem;
}
  .carrier-price{
    color: $body-color;
  }
//order list final recap
.order-line:not(:last-child){
  margin-bottom: $spacer/2;
}
.order-confirmation-table{
  padding: $spacer;
}


//forms
custom-file-label.selected:after{
  content: "" !important;

}
.label{
  @extend label;
}
//nav tabs
.nav-tabs  {
  .nav-link{
  color:$gray-600;
  font-family: $font-bold;
  }
  .nav-link.active,
  .nav-item.show .nav-link {
    //border-bottom-width: 3px;
  }
}
.tab-content{
  padding-top: $spacer;
}
//products-section-title
.products-section-title{
  text-transform: $section-title-text-transform;
  text-align: $section-title-text-align;
  display: block;
  margin-bottom: 2.6rem;
  margin-top: 4.6rem;
  @extend .h2;
  font-family: $section-title-font-family;
  color:$section-title-color;
  @include font-size(1.875rem);
  @include media-breakpoint-mobile() {
    margin-top: 3.5rem;
    margin-bottom: 3rem;
  }
}

@include media-breakpoint-mobile() {
  .modal-footer{
    flex-direction: column;
    flex-direction:column-reverse;
    & > .btn{
      margin: 0;
      width : 100%
    }
    & .btn:not(:last-child){
      margin-top: $spacer/2;
    }

  }
}

.nav-tabs {
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  &::-webkit-scrollbar {
    display: none;
  }
  .nav-link {
    white-space: nowrap;
  }
}

//forms
main > .notifications-container{
  @extend .l-wrapper;
}
.notifications-container{
  ul{
    margin-bottom: 0;
  }
}
.js-invalid-feedback-browser:empty{
  display: none;
}
.form-group{
  label,.label{
  small,
  .small{
    font-size: .75 * $font-size-base;
  }
  }
}
@include media-breakpoint-desktop{

.page-content,
.checkout-form,
.page-wrapper--order-confirmation{
  form:not(.w-100){
    width: 75%;
    max-width: 450px;
    margin: 0 auto;
  }
}
.page-wrapper--order-detail{
  form{
    margin: unset;
  }
}
}
.form-footer{
  margin-top: $spacer;
}
.page-footer{
  background: none;
}

// badge order history
.badge.bright{
  color:#fff;
}
.dropdown-toggle:after {
    margin-left: 0.255em;
    content: "";
    padding: 2.5px;
    border: 1px solid $body-color;
    transform: rotate(45deg);
    border-top: 0;
    border-left: 0;
    vertical-align: 3px;
}

button:disabled{
  cursor: not-allowed;
}

// Newsletter Footer
.nwlt-title{
  @extend .h2;
  text-align: center;
  margin-top:2.6rem;
  margin-bottom:1.4rem;
  @include font-size(1.875rem);
  display: block;

}
.block_newsletter{
  background: #F6F6F6 url('/img/cms/bg-newsletter.jpg') no-repeat;
  background-size: cover;
  padding: 2.5rem 0 3rem 0;
  @include media-breakpoint-mobile() {
    padding: 0.5rem 0 0.5rem 0;
    background-position: center;
  }
  p{
    margin-bottom:2rem;
  }
  .ps_emailsubscription_input{
    border:inherit;
    box-shadow: inherit;
  }
  .btn-primary{
    margin-left:1rem;
    padding:0 1.9rem 0 1.9rem;
    @include media-breakpoint-mobile() {
      padding:0 1rem 0 1rem;
      margin-left:0;
    }
    color:$white;
    background: $primary;
  }
  #gdpr_consent{
    margin-top:1.2rem;
    @include media-breakpoint-mobile() {
      margin-top:0.85rem;
    }
  }
}
.border-red{
  border:1px solid red;
}
.breadcrumb{
  background: inherit;
  font-size: 0.75rem;
  letter-spacing: 1.85px;
  a{
    color: $body-color;
  }
}
@include media-breakpoint-mobile() {
  #mobile_top_menu_wrapper{

.blockcart .cart-products-count{
    right: 0;
}
  #_modal_logo{
    .logo{
      max-width:130px;
      height:40px;
    }
  }
  #_modal_userinfo, #_modal_shopping_cart{
    svg{
      width:25px;
      height:25px;
    }
  }
.modal-header{
  border:none;
  padding:0 0.5rem 0 0.5rem;
}
  .close-modal{
    color:$body-color;
    font-size:2.5rem;
    background: none;
    border:none !important;
  }
}
}

/* couleur surlignage texte*/
/* pour Firefox */
::-moz-selection {
 background-color:$primary;
 color: #fff;
}
/* pour Safari et Chrome */
::selection {
 background-color:$primary;
 color: #fff;
}
.page-item{
  font-family: $font-bold;
  font-size: 0.875rem;
  .material-icons{
    color:$primary;
  }
}
.logo-sticky{
  display: none;
}
.headroom--unpinned {
  .if_sticky{
    @extend .d-none;
    @include media-breakpoint-mobile() {
      display: block !important;
      margin: auto;
      max-width: initial;
    }
    transition: 0.5s;
  }

  .logo-sticky{
    display: block;
    max-height: 40px;
    max-width: 120px;
    transition: 0.5s;
    @include media-breakpoint-mobile() {
      display: none;
    }
  }

  #_desktop_top_menu{
      transform: translateY(-63px);
      transition: 0.5s;
      @media screen and (max-width: 1200px) {
        max-width: 680px;
      }
  }

  .li-niveau1 a .advtm_menu_span {
    padding: 0 8px;
    @media screen and (max-width: 1200px) {
      padding: 0 6px;
    }
  }

  @media screen and (max-width: 1200px) {
    #adtm_menu .li-niveau1,
    #adtm_menu .li-niveau1 a.a-niveau1,
    #adtm_menu .li-niveau1 a.a-niveau1 .advtm_menu_span {
      min-height: 28px;
      line-height: 28px;
    }
  }
}

// Elementor
.product-description{

  #caracteristiques-produit-elementor{
    .ce-gallery-icon {
      min-width: 70px;
    }
    figcaption{
        @include font-size(1.125rem);
        font-family: $font-medium;
        padding:0 1rem 0 1rem;
    }
    @include media-breakpoint-mobile() {
      .ce-gallery-item{
        width:100%;
        display: flex;
        align-items: center;
        margin-bottom: 1.87rem;
        img{
          max-width: 70px;
          object-fit: inherit;
          height: auto;
        }
        figcaption{
          text-align: left;
          margin-top: 0;
          margin-left:1.5rem;
        }
      }
    }
    img{
      max-width: 90px;
      object-fit: inherit;
      height: auto;
    }
  }

  .titre-ingredient-section{
      .elementor-heading-title{
          @include font-size(2.81rem);
      }
  }
    #ingredients-produit{
      position: relative;
      .elementor-row{
        &:first-child{
          @extend .offset-xxl-2;
          @extend .col-xxl-8;
          @extend .offset-xl-1;
          @extend .col-xl-10;
          @extend .col-12;
        }
      }
      .elementor-heading-title{
        text-transform: uppercase;
        font-family: $font-bold;
          @include font-size(1.125rem);
          height:40px;
          border-bottom:1px solid $border-color-2;
          line-height: normal;
      }
      .origine-produit{
        margin-bottom:1rem;
        .elementor-image-box-content{
          //@extend .d-flex;
        }
        .elementor-image-box-title{
          text-transform: uppercase;
          font-family: $font-bold;
          padding-right:5px;
          min-width:82px;
        }
      }
      @include media-breakpoint-mobile() {
        .elementor-element-populated{
          padding:10px;
        }
        .elementor-image-box-wrapper{
          display: flex;
          text-align: left;
        }
      }

    }

    #description{
      @include media-breakpoint-mobile() {
        .elementor-element-populated{
          padding:1rem;
        }
      }
    }

    .section-ccc-produit{
      @extend .offset-xxl-2;
      @extend .col-xxl-8;
      @extend .offset-xl-1;
      @extend .col-xl-10;
      @extend .col-12;

      @include media-breakpoint-mobile() {
        display: flex;
        .elementor-column-gap-default .elementor-row {
          flex-direction: column-reverse;

        }

        .bloc-image{
          .elementor-element-populated{
            padding:0 !important;
          }
        }

      }
      color:$white;
      .elementor-heading-title{
          @include font-size(2.81rem);
      }
      .bloc-primary{
        max-width: 770px;
          @include media-breakpoint-mobile() {
            padding:10px;
          }
      }
      .bloc-primary-text{
        max-height: 286px;
        width: 770px;
        margin-right: -9rem;
        z-index: 9;
        margin-top: 125px;
        margin-left: 2rem;
        @include media-breakpoint-mobile() {
          max-height: inherit;
          width: 100%;
          margin-right: inherit;
          z-index: 9;
          margin-top: inherit;
          margin-left: inherit;
        }
      }

    }
#section-triman-produit{
      .elementor-heading-title{
          @include font-size(2.81rem);
            @include media-breakpoint-mobile() {
              @include font-size(2.5rem);
              margin-left: 80px;
            }
      }
      @include media-breakpoint-mobile() {
        .elementor-image{
          img{
            position: absolute;
            top:0;
          }
        }
        .elementor-text-editor{
          display: block;
          width:100%;
        }
      }

      .elementor-element-populated{
        padding:0 !important;
      }

    }
}
.page-cms{
  .page-content{
    padding:0;
  }
  .page-wrapper{
    border:none;
  }
  .l-wrapper{
    position:relative;
  }
h2{
  @include font-size(1.875rem);
}
.cms-header-elementor{
  color:$white;
  text-transform: uppercase;
  text-align: center;
  @include font-size(2.813rem);
  @include media-breakpoint-mobile() {
    @include font-size(1.875rem);

  }
}
.elementor-bloc-titre-main{
  h1{
    @extend .cms-header-elementor;
    color:$body-color;
  }

}
.breadcrumb_absolute_elementor{
  .breadcrumb-item.active, .breadcrumb-item+.breadcrumb-item:before{
    color: $white;
  }
    position: absolute;
    top: 50px;
    color: $white !important;
    z-index: 9;
    a{
      color: $white;
    }
}
.elementor-ico-reassurrance{
  strong{
    @include font-size(1.125rem);
    padding-bottom:1rem;
    display: block;
  }
}
.bloc-elementor-2-col{
  .elementor-row{
    @extend .offset-xl-2;
    @extend .col-xl-8;
    @extend .col-12;
  }
}
.card .card-product{
  background: $gray-200;
  border: inherit;
}
.page-content form{
  width: 100%;
  max-width: inherit;
  margin: inherit;
}
.product-title{
  min-height: 65px;
}
.bloc-elementor-push-corail{
  .elementor-row{
    @extend .offset-xl-2;
    @extend .col-xl-8;
    @extend .col-12;
  }
}
.elementor-content-2-colonnes{
  .elementor-row{
    @extend .offset-xl-2;
    @extend .col-xl-8;
    @extend .col-12;
  }
}
}

#freelivery_remaining{
  font-size: 0.875rem;
  font-family:$font-bold;
  flex-direction: inherit;
  padding: 12px 1rem 6px 1rem!important;
  border:1px solid $primary;
  display: inline-block;
  #freelivery_remaining_amount{
    margin-left:0.5rem;
  }
}
#freelivery_remaining_hr{
  display: none;
}
#js-checkout-summary{
  #freelivery_remaining{
    display: none;
  }
}

#order-confirmation{
  #content-hook_order_confirmation{
    .card-title{
      .material-icons{
        font-size:3rem;
        margin-top:0.5rem;
        margin-right:1rem;
      }
    }
  }
}
#fmeStorePage{
  .btn-primary{
    padding:15px 25px 15px 25px;
  }
}
.js-cancel-address{
  margin-top: 2rem;
  margin-left: 1rem
}
.slick-list{
  .product-miniature{
    max-width: inherit !important;
  }
}

/* Fake requiried input */
#f-phone_54163{
  .text-muted{
    display: none;
  }
}
#category{
  .product-description{
    .product-title{
      min-height: inherit;
    }
    .ratingText{
      display: none;
    }
  }
}
.page-cms .l-wrapper{
  position: inherit;
}
