.l-header{
  #search_widget{
    max-width: 200px;
    min-width: inherit;
    overflow: inherit;
    float: none;
    form input{
      border-radius: 25px;
      background: $lightGray;
      padding: 10px 45px 10px 22px;
    }
    button{
      position:absolute;
      right:1rem;
      border: none;
      top:7px;
      background-color: initial;
      &:focus {
        outline: 0;
        border:0;
      }
    }
    ::placeholder {
      font-size:0.9rem;
      color: rgba(122, 122, 122, 0.54);
    }
  }
}
.search-widget{
  max-width: 450px;
  margin: 0 auto;
}
.search-widget__group{
  position: relative;
}
.search-widget__input-right{
  padding-right: 50px ;
}
.search-widget__btn{
  position: absolute;
  right: 0;
  top: 0;
}
@include media-breakpoint-mobile{
  .search-widget{
    max-width: unset;
  }
}
