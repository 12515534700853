@include media-breakpoint-desktop {
  .collpase--facet {
    &.collapse {
      display: block;
    }
  }
  .facet__block:not(:last-child) {
    padding-bottom: $spacer;
  }
}
@include media-breakpoint-mobile {
  .facet__block:not(.show) {
    padding-bottom: 0;
  }
}

.facet__block:not(:last-child){
  margin-bottom: $spacer;
  border-bottom: 1px solid $card-border-color;

}

.facet__header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: $spacer/1.5;
}

.facet__title {
  margin-bottom: 0;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.left-column .search_filters {
  @extend .card-block;
}

.custom-color{
  &.custom-control{
    padding-left: 0;
    margin-bottom: .25rem;
  }
  .custom-control-label:before,
  .custom-control-label:after
  {
    display:none
  }
  .color{
    margin-right: .25rem;
    border: none;
  }
  .color__label{
    //dis
    line-height: 1.25;
    display: inline-block;
  }
  &.custom-control--active{
    .color{
      border: 2px solid black;
    }
  }
}



.btn--clearfilter{
  margin-bottom: $spacer;
}
//
.active-filter__title{
  margin-bottom: $spacer/2;
  font-weight: bold;
}
.active_filters__list{
}
.active_filters{
  background-color: $bg-dark;
padding: $spacer $spacer ($spacer*.75);
  margin-bottom: $spacer*1.25;
}
.active_filters__list-item{
  margin-bottom: $spacer/4;

}
.faceted-overlay {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: rgba(25, 25, 25, 0.5);
  z-index: $zindex-modal+1;
}

.faceted-overlay .overlay__inner {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.faceted-overlay .overlay__content {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.faceted-overlay .spinner {
  width: 75px;
  height: 75px;
  display: inline-block;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.05);
  border-top-color: #fff;
  animation: spin 1s infinite linear;
  border-radius: 100%;
  border-style: solid;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}


#_desktop_search_filters_wrapper {
  margin-top:1rem;
  .card{
    border: none;
    padding-left:0;
    padding-right:0;
  }
  .card-header{
    background: none;
    padding-left:0;
    border:none;
    padding:0;
    cursor:default;
    .PM_ASBlockTitle{
      font-family: $font-bold;
      @include font-size(1.125rem);
      padding:0;
      &:after{
        content: "";
      }
    }
  }
  .facet__title{
    color:$primary;
    text-transform: uppercase;
    letter-spacing: 1.75px;
    font-weight: inherit;
    font-family: $font-bold;
    display: inline-block;
    width:100%;
    cursor: pointer;
  }
  .PM_ASCriterionsGroupTitle{
    padding: 1.25rem 0 5px 0;
    border:0;
    border-top:1px solid $border-color-2;
  }
.PM_ASLabelLink:hover{
  font-weight: inherit;
}
.PM_ASBlockOutputVertical a{
  color:$body-color;
}

.PM_ASCritRange .ui-slider-range{
  background: rgba($primary, 0.4);
}
.PM_ASCritRange .ui-state-default, .ui-widget-content .ui-state-default{
  background: $primary;
}
.ui-slider .ui-slider-handle{
  border-radius: 50%;
}
.ui-widget-content{
  border:none;
}
.ui-slider-horizontal{
  height:0.5rem;
}
.ui-slider-horizontal .ui-slider-handle{
  top:-7px;
}
.ui-state-default, .ui-widget-content .ui-state-default{
  border:1px solid $primary;
}

.PM_ASCriterionsOutput{
  &:after{
    content: "";
    position: absolute;
    right: 3px;
    font-size: 18px;
    top: 0;
    color:$body-color;
  }
[aria-expanded="true"]{
    &:after{
      content: "\e15b";
      font-family: Material Icons;
      font-feature-settings: "liga";
      cursor: pointer;
    }
  }
[aria-expanded="false"]{
      &:after{
        content: "\e145";
        font-family: Material Icons;
        font-feature-settings: "liga";
        cursor: pointer;
      }
    }
  }
}
#search_filters .ui-slider-horizontal .ui-slider-handle {
  margin-left: -1px;
  cursor: pointer;
}

#search_filters .ui-widget-header {
  background: #555;
}

#search_filters .ui-slider .ui-slider-handle {
  top: -.45em;
  width: 0.4em;
  background: #fff;
  border: 1px solid #555;
}

#search_filters .ui-slider-horizontal {
  height: .4em;
}
