//
// Color system
//
$white:         #fff;
$primary:       #9BBDAA;
$secondary:     #D7E5DD;
$black:         #000;
$skin:          #F3BE9A;
$body-color:    #353535;
$gray-200:      #F6F6F6;
$gray-300 :     #f1f1f1;
$gray-400:      #ced4da;
$gray-500:      #939288;
$lightGray :    #F3F1EC;
$gray-900:      #939288;
$border-color:  $gray-200;
$border-color-2:#DBDBDB;
$hr-border-color: $border-color-2;
$gray-600 : #939288;


// Options
$enable-rounded:            false;
$enable-shadows:            true;

// Grid containers
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1170px
);

// Grid breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1201px,
  xxl:1920px
);

//
// Links
//
// Style anchor elements.


$link-hover-decoration:     none;
$breadcrumb-active-color: $body-color;
$breadcrumb-padding-y : 0;
$breadcrumb-padding-x : 0;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif:      'lcdp_brandonregular', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$headings-font-family:        'lcdp_brandonmedium',$font-family-sans-serif;

$font-bold : 'lcdp_brandonbold', $font-family-sans-serif;
$font-black : 'lcdp_brandonblack', $font-family-sans-serif;
$font-medium : 'lcdp_brandonmedium', $font-family-sans-serif;
$font-light : 'lcpd_brandonlight', $font-family-sans-serif;



$font-size-base:              1rem; // Assumes the browser default, typically `16px`


$h1-font-size:                3rem;
$h2-font-size:                2.25rem;
$h3-font-size:                1.25rem;
$h4-font-size:                1.125rem;
$h5-font-size:                $font-size-base;
$h6-font-size:                $font-size-base * 0.9375;

@mixin h1 {
  font-family: 'lcdp_brandonregular', $headings-font-family;
  text-transform: uppercase;
  @include font-size($h1-font-size);
  color: $body-color;
}
@mixin h2 {
  font-family: 'lcdp_brandonbold', $headings-font-family;
  text-transform: uppercase;
  @include font-size($h2-font-size);
  letter-spacing: 2px;
  color: $body-color;
}
@mixin h3 {
  font-family: 'lcdp_brandonbold', $headings-font-family;
  text-transform: uppercase;
  @include font-size($h3-font-size);
  color: $body-color;
}

$display1-size: 3.125rem;
$display1-weight: 600;
$display2-size: 2.188rem;
$display2-weight: 400;
$display3-size: 1.563rem;
$display3-weight: 400;
$display4-size: 1.25rem;
$display4-weight: 400;

$small-font-size:             $font-size-base * 0.875;


// Components
//
// Define common padding and border radius sizes and more.

$box-shadow:                  2px 2px 8px 0 rgba($black,.2);
$enable-shadows : false;
$input-color: $body-color;

$input-height: 50px;

$custom-select-border-color : $primary;
$custom-select-focus-box-shadow: none;

$input-box-shadow: none;
$input-focus-box-shadow :none;

// Navs

$nav-tabs-border-width:             2px;
$nav-tabs-border-color:             $gray-300 !default;
$nav-tabs-link-hover-border-color:  transparent transparent $nav-tabs-border-color;
$nav-tabs-link-active-color:        $primary;
$nav-tabs-link-active-border-color: transparent transparent $primary;
// Pagination

$pagination-border-color:           transparent;
$pagination-disabled-border-color:  $gray-400;
$pagination-hover-border-color:     $gray-400;
$pagination-active-bg: transparent;
$pagination-active-color: $primary;
$pagination-color: $body-color;
$pagination-hover-bg: transparent;
$pagination-hover-border-color: transparent;
$pagination-focus-box-shadow : none;
$pagination-focus-outline: none;

// Modals
$modal-lg:                          990px;

//tables
$table-bg : $white;
