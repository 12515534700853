.displayBanner{
  background: $primary;
  color:$white;
  font-size: 0.75rem;
  font-family: $font-medium;
  p{
    margin-top:0.75rem;
    margin-bottom: 0.75rem;
  }
  a{
    color:$white;
  }
  .centralText{
    text-transform: uppercase;
    @include media-breakpoint-mobile() {
      overflow: auto;
      overflow-y: hidden;
      white-space: nowrap;
    }
  }
  .text1{
    text-align: left;
    padding-left:20px;
    &:before{
      content: url('/img/cms/mail.svg');
      position: absolute;
      left: 0;
      bottom:10px;
    }
  }
  .text2{
    text-align: right;
    p{
      display: inline-block;
      position: relative;
      &:before{
        content: url('/img/cms/handshake.svg');
        position: absolute;
        left: -20px;
        bottom:-4px;
      }
    }

  }
}
.home-slider{
  min-height: 35.41vw;
  @include media-breakpoint-mobile() {
    min-height: 147.05vw;
  }
  .cb-item-content img{
    width:100%;
    height:auto;
  }
}
.videoWrapper .cb-item{
	.custom-html{
		@extend .embed-responsive;
		@extend .embed-responsive-16by9;
    max-height: 770px;
		@include media-breakpoint-mobile() {
		height: 90vh;
		}
		video{
			@extend .embed-responsive-item;
			background: $primary;
			object-fit: cover;
		}
	}
}
.pushCategory{
  margin-top:2.9rem;
  .cb-item-content .cb-img{
    overflow: hidden;
    width: 100%;
    height: auto;
    transition: all .5s ease-out;
    max-width: 183px;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
  }
  a{
    max-width: 183px;
    width: 100%;
    height: auto;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: .5rem;
    display: inline-block;
    position: relative;
    margin: auto;
    @include media-breakpoint-mobile() {
      display: block;
      overflow: inherit;
    }
    &:after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }
  p{
    margin-top:0.5rem;
  }
  img{
    border-radius: 50%;
    margin-bottom:1rem;
    &:hover{
       transform: scale(1.2);
       @include media-breakpoint-mobile() {
         transform: none;
       }
    }
  }
  .swiper-wrapper{
    @include media-breakpoint-up(lg) {
      transform: none !important;
    }
  }
  p{
    font-family: $font-bold;
  }
    @extend .px-0;

}

.bloc-ritual{
  margin-top:4.75rem;
  background: $skin;
  .bloc-before-promo{
    margin-bottom: 2.25rem;
    margin-top: 3rem;
  }
  .cb{
    @extend .d-flex;
    @extend .offset-xl-1;
    @extend .col-xl-10;
    @extend .offset-xxl-2;
    @extend .col-xxl-8;
    @extend .col-12;
    @extend .align-items-center;
    @extend .px-0;
    	@include media-breakpoint-mobile() {
        display: block !important;
      }
  }
  .custom-html{
    text-align: left;
    em{
      @include font-size(1.625rem);
      font-style: normal;
      font-family: $font-light;
      @include media-breakpoint-mobile() {
        text-align: left;
        display: block;
      }
    }
    p {

      strong{
      @include font-size(2.81rem);
      font-weight: normal;
      line-height: 1;
      margin-bottom:1.25rem;
      display: block;
      @include media-breakpoint-mobile() {
        text-align: left;
      }
    }
    }

    .btn-inline{
      margin-top:1.25rem;
    }
  }
  .code-promo{
    .custom-html{
      @include media-breakpoint-mobile() {
      text-align: center;
    }
    }
    margin-bottom:2.4rem;
    @include media-breakpoint-mobile() {
      text-align: center;
    }
    p{
      &:first-child{
        text-transform: uppercase;
        @include media-breakpoint-mobile() {
          margin-top: 1.25rem;
        }
          font-family: $font-bold;

      }
      strong{
        display: inline !important;
        font-size: inherit;
        @include font-size(1.5rem);
        font-family: $font-black;
      }
    }
    img{
      border-radius: 50%;
      border:2px solid $white;
      margin-right:1.5rem;
      @include media-breakpoint-mobile() {
        margin-right:0;
        width:172px;
        height:172px;
      }
    }
    .cb-item-content{
      @extend .d-flex;
      @extend .align-items-center;
      @include media-breakpoint-mobile() {
        flex-direction: column;
      }
    }
    em{
      border-top:1px solid $body-color;
      padding: 5px 0 5px 0;
      font-size:1rem;
      padding: 5px 0;
      margin-top: 5px;
      display: inline-block;
      @include media-breakpoint-mobile() {
        font-family: inherit;
      }
    }
  }
}

.bloc-brand{
  .cb-item{
    @extend .px-0;
  }
  .cb-item-content{
    .cb-img{
        position: absolute;
        top: 45%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include media-breakpoint-down(xl) {
          max-width:70%;
        }
    }
  }
  .blurImage{
    background-size:cover;
    width:100%;
    height:100%;
    overflow: hidden;
    img{
      filter: blur(40px);
      width: 100%;
      backdrop-filter: brightness(0.65);
    }

  }
  p{
    @include font-size(1.625rem);
    @include media-breakpoint-down(xl) {
      font-size: 1rem;
    }
    color:$white;
    text-transform: uppercase;
    font-family: $font-black;
    text-shadow: 2px 2px 16px rgba(255, 255, 255, 0.4);
  }
  .html-over .custom-html{
    bottom:3rem;
      @include media-breakpoint-down(xl) {
        bottom:1.5rem;
      }
    top:inherit;
    padding:0 1rem;
  }
}
.review-title{
  h2{
    @include font-size(1.875rem);
    margin-bottom:0px;
  }
  a{
    color:inherit;
    text-decoration: none;
  }
  margin-bottom:20px;
  margin-top:4rem;
}
.bloc-review{
  @include media-breakpoint-mobile() {
    overflow-x: hidden;
  }
  margin-bottom: 4.75rem;
  .review{
    background: $gray-200;
    padding:1rem;
    min-height: 390px;
    @include media-breakpoint-mobile() {
      min-height: 250px;
    }
    p{
        &:nth-child(2) {
          min-height:200px;
          @include media-breakpoint-mobile() {
            min-height:140px;
          }
        }
    }

    img{
      margin-top: .25rem;
      margin-bottom:10px;
    }
    strong, b{
      font-size:1.125rem;
      font-family: $font-bold;
      margin-bottom:10px;
      display: block;
    }
    em{
      font-family: $font-medium;
      color: $gray-500;
      font-style: normal;
      margin-top:10px;
      display: block;
      //padding: 0 2.18rem 0 2.18rem;
    }
    a{
      letter-spacing: 1.66px;
      font-family: inherit;
      color:$body-color;
      font-size: 0.875rem;
      text-decoration: none;
      margin-bottom:1.875rem;
    }
  }
  .sw-nav{
    background: none;
    box-shadow: none;
    &:before{
    width: 18px;
    height: 18px;
    border: 2px solid $primary;
    border-width: 0 0 3px 3px;
    margin-left: 25px;
    }
    &:hover{
      background: none;
    }
  }
  .swiper-container{
    position: initial;
  }
  .sw-nav.next{
    right:-50px;
    @include media-breakpoint-mobile() {
      right:-6px;
    }
  }
  .sw-nav.prev{
    left:-50px;
    @include media-breakpoint-mobile() {
      left:-6px;
    }
  }
}
.press-bloc{
  p{
      @include font-size(1.5rem);
      font-family: $font-light;
      padding:0 3rem 0 3rem;
        @include media-breakpoint-mobile() {
          font-size: 1.56rem;
          padding: 0 1rem 0 1rem;
        }
  }
  .swiper-pagination-clickable{
    margin-bottom: 3.3rem;
    @include media-breakpoint-mobile() {
      overflow: auto;
      overflow-y: hidden;
      white-space: nowrap;
    }
  }
  .sw-pgn-bullet{
    display: inline;
    border: none;
    border-radius: inherit;
    background: none;
    height:63px;
    width:100%;
    margin-right:2.5rem;
    @include media-breakpoint-mobile() {
      margin-right:1rem;
    }
  }
  .sw-nav{
    background: none;
    box-shadow: none;
    top:70%;
    @include media-breakpoint-mobile() {
      top:62%;
    }
    &:before{
    width: 12px;
    height: 12px;
    border: 2px solid $primary;
    border-width: 0 0 3px 3px;
    margin-left: -25px;
    }
    &:hover{
      background: none;
    }
  }
  .swiper-container{
    position: initial;
  }
  .cb-carousel{
    @extend .offset-xxl-1;
    @extend .col-xxl-10;
    @extend .col-12;
  }
}
.bloc-brand-title{
  margin-top:4.75rem;
  margin-bottom:2.5rem;

  @include media-breakpoint-mobile() {
    h2{
      font-size:1.875rem;
    }
    margin-top: 2.5rem;
    margin-bottom: 3.5rem;
  }
}
.text-title-blog{
  h2{
    margin-bottom: 2.15rem;
  }
}
.texte-tri{
  display: flex;
  font-size: 0.75rem;
}
