.l-header{
  background-color: $header-bg;
  margin-bottom: 0.1rem;
  .myAccountIcon{
    height:20px;
    width:18px;
  }
  .logo{
    max-height:52px;
    max-width: 216px;
    fill:$body-color;
  }
  .header__logo svg{
    @include media-breakpoint-mobile{
      max-height:32px;
      max-width: 120px;
    }
  }
  .iconStores{
    margin-right:1.3rem;
    text-align: center;
    a{
      color:$body-color;
    }
    svg{
      width:24px;
      height:24px;
    }
    small{
      display: block;
    }
  }
}

#category{
  .l-header{
    border-bottom:1px solid $border-color-2;
    margin-bottom:15px;
  }
  #menu{
    margin-bottom: 6px;
  }
}

.header__up{
  align-content: center;
}

.header-top{
  flex-wrap: wrap;
  padding-top: 12px;
  @include media-breakpoint-mobile() {
    padding: 0;
  }
}
.language-selector{
  .btn{
    font-family:inherit;
  }
  @include media-breakpoint-desktop() {
    .dropdown-menu.show {
      left: inherit !important;
      right: 0;
      transform: translate3d(-5px, 30px, 0px) !important;
    }
  }
}

.header__search{
  display: flex;
  flex: 1;
  padding-right: $grid-gutter-width/2;
  padding-left: $grid-gutter-width/2;
}
.header__right,
.header-nav__right{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.header__rightitem:not(:last-child){
  margin-right: $spacer/2;
}
.header__logo--checkout{
  max-width: 200px;
}
.header-nav--checkout{
  padding-top: $spacer;
  padding-bottom: $spacer;
}
@include media-breakpoint-mobile{
   .header__logo{
    max-width: 33.33333%;
  }
  .header__logo--checkout{
    max-width: 50%;
  }
  .header__search {
    width: 100%;
    padding: 0;
    //flex: auto;
    margin-top: 10px;
  }
  .header__logo--checkout{
    margin: 0 auto;
  }
}
@include media-breakpoint-desktop(){
  .header-top__col{
    width: 30%;
  }
}
@include media-breakpoint-up(xl) {
  .header-top__col{
    width: 25%;
  }
}
.headroom {
 transition: all 0.3s ease-out;
}
.headroom--pinned {
 	 position: relative !important;
}
.headroom--unpinned{
   position: fixed !important;
   width: 100%;
   top:0;
   background:$white;
   max-width:100%;
   z-index:999;
   height:65px;
   border-bottom:none !important;
   #_desktop_top_menu{
     margin-top:14px;
   }

}

#_mobile_language_selector #language-selector-label { 
  display: none !important;
}