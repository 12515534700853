//.blockcart{
//  color:$blockcart-color;
//  background-color: $blockcart-background-color;
//  padding:$blockcart-padding;
//}
//.blockcart.inactive{
//  color:$blockcart-inactive-color;
//  background-color: $blockcart-background-color;
//  .material-icons{
//    color:$blockcart-inactive-color;
//  }
//}
.product-line-actions{
  display:flex;
  justify-content: space-between;
  align-items: center;
  .input-group.bootstrap-touchspin{
    max-width: 130px;
  }

}
.product-line-grid{
  .product-line__title{
    margin-bottom: $spacer*.75;
    display: inline-block;
    color: $body-color;
  }
  .product-price{
    display: flex;
    align-items: center;
    .discount{
      font-size: $font-size-sm;
      line-height: 1;
    }
  }
  .product-discount{
    margin-right: $spacer/2;
  }

}
.product-line__img{
  margin-right: $spacer;
}
@include media-breakpoint-mobile {
  .product-line__img{
    width: 100px;
    height: auto;
  }
  .product-line-actions {
  justify-content: space-between;
    margin-top: $spacer;
    .product-price{
      margin:0 $spacer*2;
    }
  }
}
@include media-breakpoint-mobile() {
  .product-line__img{
    width: 50px;
  }
  .product-line-actions {
    .product-price{
      margin:0 $spacer;
    }
  }

}

.cart__card-body{
  position: relative;
 .cart__card-loader{
   position: absolute;
   width: 100%;
   height: 100%;
   left: 0;
   top: 0;
   align-items: center;
   justify-content: center;
   display: none;
   background-color: rgba($white, .85);
   opacity: 0;
   transition: $transition-fade;
   z-index: 3;
 }
  &.is--loading{
    .cart__card-loader {
      display: flex;
      opacity: 1;
    }

  }
}
.nav-tabs--center{
  justify-content: center;
}
@include media-breakpoint-mobile{
  .checkout {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    padding: 1rem;
    background-color: $white;
    a{
      width: 100%;
    }
  }
}
.product-line__body{
  padding-right: $spacer/4;
}

//order confirmation table
.total-value{
  font-family: $font-bold;
}
.table__title-head{
  margin-bottom: 0;
  font-size: $font-size-base;

}
/* CART */
.promo-code{
  margin-left: -$card-spacer-x;
  margin-right: -$card-spacer-x;
}
.promo-code-alert {
  display: none;
}
.cancel-promo{
  text-align: center;
}


.cart-summary-line{
  display: flex;
  justify-content: space-between;

}
.cart-total{
font-weight: bold;
}
.card-body--summary{
.cart-total{
  @extend .total-value;
  padding: $spacer/4;
  .label{
    margin-bottom: 0;
  }
}
  &.card-body{
    padding-bottom: 0;
  }
}
.media-list__item:not(:last-child){
  margin-bottom: $spacer/2;

}

//cart summary
.link__showsummary{
  .material-icons{
    @extend .icon-collapse;
  }
  &[aria-expanded=true] .material-icons{
    transform: rotateZ(180deg);

  }
}
.cart-item:not(:last-child){
  border-bottom: 1px solid $border-color;
  margin-bottom: $spacer;
  padding-bottom: $spacer;
}
.product-line-info{
  margin-bottom: $spacer/2;
}
.promo-code-button{
  text-align: center;
  .collapse-button[aria-expanded=true] {
    display: none;
  }
}
.with-discounts{


}
.promo-highlighted{
  text-align: center;
  font-size: $font-size-sm;
  margin-top: $spacer;
  margin-bottom: $spacer/2;
}
.promo-discounts{
  font-weight: $font-weight-bold;
  text-align: center;
  color: $gray-700;
  .code{
    color: $tertiary;
  }
}
.form__add-voucher{
  margin-bottom: $spacer;
}
.promo-code{
  &__content{

    padding: $card-spacer-x;
  }
 .collapse-button{
   text-align: center;
   display: block;
   color: $primary;
 }
}

.blockcart .cart-products-count {
    position: absolute;
    top: -3px;
    right: -8px;
    background: $body-color;
    color: $white;
    border-radius: 100%;
    font-size: .625rem;
    font-weight: 400;
    padding: 0.0625rem 0.1875rem;
    line-height: 1;
}
#blockcart-modal{
  .h3{
    font-family: $font-bold;
  }
  .btn-primary{
    background: rgba($primary, 0.4);
    border:none;
    &:hover, &:active{
      color:$body-color;
      background: $primary;
      border-color:$primary;
    }
  }
  .modal-cart__name{
    font-weight: normal;
    font-family: inherit;
  }
  .material-icons{
    margin-right: 5px;
    margin-top: 3px;
  }
  .btn{
    padding: inherit;
    min-height: 47px;
    .material-icons{
      margin-right: 5px;
      margin-top: -2px;
    }
    &:hover, &:active{
      color:$body-color;
    }
  }
}

.cart-grid{
  .card-header{
    background: none;
    padding-left:0;
  }
  .card{
    border:none;
    h1, .h2{
      @include font-size(1.875rem);
      font-family: $font-bold;
    }
  }
  .h2{
    border-bottom:1px solid $border-color-2;
    line-height:2.2;
    @include media-breakpoint-mobile() {
      line-height:1.1;
      padding-bottom:1rem;
    }
  }
  .product-line__img{
    width:95px;
    background: $gray-200;
  }
  .card-body{
    padding-left:inherit;
    padding-right:inherit;
  }
  .btn-link{
    border:1px solid $primary;
    padding: 13px 25px 13px;
    line-height: 1.8;
    text-align: center;
    max-width: 300px;
    .material-icons{
      margin-right:10px;
    }
  }
  .card-footer{
    border:none;
    background: none;
  }

  .cart-grid-right .cart-summary{
    background: $gray-200;
      .card-body{
        padding-left:1rem;
        padding-right:1rem;
      }
    .card-header{
      border:none;
      background: $white;
    }
    .btn-checkout{
      .btn-primary{
        background: rgba($primary, 0.4);
        padding-top:18px;
        padding-bottom:18px;
        font-size:1rem;
        border:inherit;
        &:hover{
          background: $primary;
        }
      }
      a{
          color:$body-color;

      }
    }
    .gift-img{
      max-width: 114px;
    }

  }
  #cart-subtotal-products{
    .value{
      font-family: $font-bold;
    }
  }
  .product-flag.new{
    display: none;
  }

  .pm-pm_crosssellingoncart{
    .product-title{
      min-height: inherit;
    }
    .add-to-cart{
      background: rgba($primary, 0.4);
      border:none;
      &:hover{
        background: $primary;
        color:$body-color;
      }
    }
    .product-miniature {
      margin-bottom:0;
    }
  }

}
.cart-item:not(:last-child){
  border-color : $border-color-2;
}
#cart .product-accessories .product-miniature {
    flex: 0 0 100%;
    max-width: 100%;
}
.dariqueRuleContainer{
  .custom-control-label{
    &:before{
      border-radius: 50%;
    }
  }
}
  @include media-breakpoint-mobile() {
.ets_crosssell_list_blocks {
  .h2{
    font-size: 1.875rem;
    padding-left:15px;
  }
}
}
@include media-breakpoint-mobile() {
  #cart{
    #axeptio_overlay{
      display: none;
    }
  }
}
