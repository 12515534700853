.block-categories {
  @extend .card;
}
.block-category{
  border:none;
  h1{
    font-family: $font-bold;
    @include font-size(1.875rem);
    letter-spacing: 2px;
    margin-bottom:1rem;

  }
}
.block-categories{
  @extend .card-body;
}

.block-categories {
  box-shadow:$card-box-shadow;
}

._mobile_category_header{
  @include media-breakpoint-mobile() {
  h1{
    text-align: center;
    @include font-size(1.875rem);
    margin:1.25rem 0 0 0;
    font-family: $font-bold;
    }
  }
}


#search_filter_toggler{
  background: $primary;
  border: none;
  color:$white;
  text-transform: lowercase;
  font-size: 1.125rem;
  padding: 9px 27px 10px 27px;
  font-family: $font-bold;
  height:45px;
  svg{
    height:24px;
    width:25px;
    margin-right: 6px;
    margin-bottom:4px;
  }
}
#js-product-list-top{
  .custom-select{
    height:45px;
  }
}


//block categories
.block-categories a{
  color:$body-color;
}
.category-sub__item{
  display: block;
  width: 100%;
}
.category-sub__item--0{
  border-bottom: 1px solid $border-color;
  padding: $spacer/4 0;
}
.category-sub__item:not(.category-sub__item--0):not(.category-sub__item--1) {
  padding-left: $spacer/4;
}
.block-categories .collapse-icons .remove {
  display: none;
}
.block-categories .collapse-icons[aria-expanded=true] {
  &.remove{
  display: block;
  }
  &.add{
    display: none;
  }
}
.bg-category{
    @include media-breakpoint-mobile() {
      padding-right: 0;
      padding-left: 0;
    }
}
@include media-breakpoint-mobile() {
.m-p-0{
  margin-right: 0;
  margin-left:0;
  padding-right: 0;
  padding-left:0;
  }
}

@include media-breakpoint-mobile() {
  .products-slick .slick-list {
    padding: 0 1rem!important;
  }
}
.subcategory-image{
  span{
    width: 130px;
    height:130px;
    display: block;
    overflow: hidden;
    border-radius: 50%;
  }
  .img{
    display: inline-block;
    margin: auto;
  }
  img{
    max-width: inherit;
    transition: all .5s ease-out;
    background: $gray-200;
    &:hover{
      transform: scale(1.2);
    }
  }
  max-width: 130px;
  text-align: center;
  margin-right: 1.25rem;
  margin-left: 1.25rem;
  @include media-breakpoint-mobile() {
    max-width: inherit;
    margin-right:inherit;
    margin-left:inherit;
  }
}
#subcategories{
  margin-top: 1.875rem;
  .subcategory-name{
    color: $body-color;
    font-family: $font-bold;
    margin-top:10px;
    text-decoration: none;
    display: inline-block;
    line-height: 1.2;
  }
  hr{
    margin-bottom:1rem;
  }
}
