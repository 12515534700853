.account-links{
  @extend .align-items-stretch;
  > a{
    margin-bottom: $spacer;
  }
  .link-item{
    @extend .card;
    box-shadow:$card-box-shadow;;
    padding: $spacer;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    height: 100%;
    i{
      margin: 0 auto $spacer;
      font-size: $font-size-base*3;

    }
  }
}

.address{
  @extend .card;
  height: 100%;
}
.address-header{
  @extend .card-header;
  background-color: transparent;
}
.address-body{
  @extend .card-body;
}
.address-footer{
  @extend .card-footer;
  display: flex;
  justify-content: space-between;

}

.form--100{
  min-width: 100%;
  width: 100%;
}
.form-control-submit{
  background: $primary;
  color:$white;
  &:hover{
    color:$white;
  }
}
.form-fields{
  .form-control{
    height: inherit;
  }
}
.btn-mdp{
  font-size:0.85rem;
  border:none;
  background: none;
  margin-top: 0.5rem;
  &:focus{
    border:none;
    outline: inherit;
  }
}
.page-header--my-account{
  h1{
      @include font-size(1.875rem);
      font-family: $font-bold;
      margin: 2.625rem 2.625rem;
      @extend .text-center;
  }
}
.account-links .link-item{
  border:none;
}
#checkout{
  .checkout-process{
    .alert-info{
      color:$body-color;
      background: $gray-200;
      border:none;
    }
  }
  .step-title{
    @include font-size(1.875rem);
    font-family: $font-bold;
    letter-spacing: 2px;
  }
    .card{
      border:none;
    }
    .card-header{
      background: $white;
    }
  .checkout-process{

    .form-control{
      border-color:$skin;
    }
    .required{
      &:before{
        content: "*";
        position: absolute;
        right:-10px;
      }

    }
    label{
        font-family: $font-bold;
        position: relative;

      }
      .custom-checkbox{
        label{
          font-family: inherit;
        }
      }
      .custom-control-label{
        font-family: inherit;
      }

    }
.btn-primary{
   background: rgba($primary, 0.4);
   border:none;
    padding: 18px 25px 18px 25px;
    font-size: 1rem;
    margin-top: 40px;
   &:hover{
     color:$body-color;
     background: $primary;
   }
}
.input-group-btn{
  background: $skin;
  padding-right: 1rem;
  padding-left: 1rem;
}
.btn-mdp{
  color:$white;
  letter-spacing: 1px;
  font-family: $font-bold;
}
.tab-content section{
  border:none;
}
#js-checkout-summary{
  .h2{
    letter-spacing: 2px;
    @include font-size(1.875rem);
  }
  .card__summary{
    background: $gray-200;
  }
  .link__showsummary{
    color:$body-color;
    text-decoration: none;
    .material-icons{
      margin-right:-8px;
    }
  }
  .card-header, .card-footer{
    border:none;
  }
}
.step-edit{
  text-transform: lowercase;
  font-family: $font-light;
}
}
#content-checkout-personal-information-step{
  padding-bottom: 2.938rem;

}
#checkout-personal-information-step {
  .card-header{
    border:none;
  }
}

#checkout-addresses-step{
  .card-header{
    border-top:1px solid $card-border-color;
  }
  .address-item{
    .text-muted{
      font-family:$font-light;
    }
    .card-header{
      border-top:none;
    }
  }
}

.js-address-form{
  .link {
    color:$black;
    text-decoration: none;
  }
}
#delivery-addresses{
  margin-top:1.875rem;
  .card-header{
    border-color:$skin;
  }
  .card-footer{
    background: none;
    border-color: $skin !important;
  }

  .card{
    border:1px solid $skin;
  }
  .card-body{
    font-family: lcdp_brandonregular;
  }
  .card-body_add-address{
    color:$skin;
    font-family: $font-bold;
  }
  .bg-light{
    background: $white;
  }
}
