#blog_list_1-7 {
  .category-link{
    color:$skin;
    text-transform: uppercase;
  }
  .block_bas{
    .h3{
      font-family: $font-bold;
      text-transform: inherit;
      text-decoration: none;
      @include font-size(1.125rem);
      a{
        text-decoration: none;
        color:$body-color;
      }
    }
    .blog_desc{
      font-size:.875rem;
    }
  }
  .block_cont {
    width:96% !important;
  }
  @include media-breakpoint-mobile() {
    padding: 0 2.9rem 0 2.9rem !important;
    margin:0 !important;
    width:inherit !important;
    .slick-arrow {
      height:inherit;
      top:30%;
    }
    .slick-arrow>i{
      color:$primary;
    }
    .block_cont {
      width:inherit !important;
    }
  }

}
